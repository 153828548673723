<template>
    <a-form layout="inline" class="bottom tleft">
        <a-form-item label="用户姓名">
            <a-input
                    v-model:value="uname"
                    allow-clear
            >
            </a-input>
        </a-form-item>
        <a-form-item>
            <a-button type="primary" @click="search">
                <template #icon>
                    <search-outlined/>
                </template>
                搜索
            </a-button>
        </a-form-item>
    </a-form>
    <a-table :columns="columns" :data-source="data">
        <template #action="{record}">
            <router-link :to="'/admin/user/add?id='+record.id" v-if="hasAuth('user:edit')">编辑</router-link>&emsp;
            <a @click="delUser(record.id)" class="red" v-if="hasAuth('user:del')">删除</a>
        </template>
    </a-table>
</template>
<script>
    import {createVNode, defineComponent, onMounted, ref} from 'vue';
    import {useRouter} from 'vue-router';
    import {message, Modal} from "ant-design-vue";
    import {userList,del} from "@/network/admin";
    import moment from "moment";
    import {ExclamationCircleOutlined} from '@ant-design/icons-vue';

    export default defineComponent({
        name: 'Ulist',
        setup() {

            const columns = [
                {
                    title: 'Id',
                    /*width: 50,*/
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: '账号名',
                    // width: 60,
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '用户姓名',
                    dataIndex: 'uname',
                    key: 'uname',
                    // width: 60,
                },
                {
                    title: '用户角色',
                    dataIndex: 'rolename',
                    key: 'rolename',
                },
                {
                    title: '添加时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                    customRender: (text, record, index) => {
                        return moment.unix(text.text).format('YYYY-MM-DD HH:mm:ss');
                    }
                },
                {
                    title: '操作',
                    key: 'operation',
                    // width: 50,
                    fixed: 'right',
                    slots: {
                        customRender: 'action',
                    },
                },
            ];
            const delUser = (id) => {
                Modal.confirm({
                    title: '你确定要删除用户?',
                    icon: createVNode(ExclamationCircleOutlined),
                    content: '请选择是或否',
                    okText: '是',
                    okType: 'danger',
                    cancelText: '否',
                    onOk() {
                        del({'id': id}).then((response) => {
                            if (response.status == 'done') {
                                message.success(response.msg);
                                getList()
                            } else {
                                message.error(response.msg);
                            }
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                })
            };
            const data = ref([]);
            onMounted(() => {
                getList();
            });
            const where = ref({'uname':''});
            const uname = ref('');
            const getList = (where) => {
                userList(where).then((response) => {
                    if (response.status == 'done') {
                        data.value = response.list;
                    } else {
                        message.error(response.msg);
                    }
                })
            };
            const search = () => {
                where.value.uname = uname.value;
                getList(where.value);
            };
            return {
                data,
                columns,
                getList,
                delUser,
                uname,
                search
            };
        }
    });
</script>
<style scoped lang="less">
    .left{float: left;}
    .red{
        color: red;
    }
    .bottom {
        margin-bottom: 10px;
    }
</style>
